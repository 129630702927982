#page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px auto;
  max-width: 800px;
}
#page h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: larger;
}
#page li {
  text-align: right;
}
#page p {
  color: #333333;
  font-size: 1.3em;
  margin: 7px 0 7px 0;
  padding: 0;
  text-align: justify;
  text-indent: 8px;
}
#page ul {
  column-count: 2;
  column-gap: initial;
  margin: 8px 0px 0px 0px;
}
#page ul.oneCUl {
  column-count: 1;
}
.emojis {
  /* line-height: inherit; */
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  text-align: right;
}
.listAndTitle {
  margin-bottom: 10px;
}
.listsDiv {
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  width: 95%;
}
.notes {
  display: flex;
  flex-direction: column;
}
mark {
  background-color: #cbeec5;
  border-radius: 8px;
  margin: 0;
  padding: 2px 7px;
}
section {
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 801px) {
  #page {
    font-size: 1em;
    margin: 0px 2%;
    padding: 15px 20px;
  }
  .listsDiv {
    flex-direction: column;
    gap: 3px;
  }
}
