@font-face {
  font-display: swap;
  font-family: 'Kitab';
  font-style: normal;
  font-weight: normal;
  src: url('/src/assets/fonts/Kitab-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/Kitab-Regular.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Kitab';
  font-style: normal;
  font-weight: bold;
  src: url('/src/assets/fonts/Kitab-Bold.woff2') format('woff2'),
    url('/src/assets/fonts/Kitab-Bold.woff') format('woff');
}
html {
  background-color: #28252e;
  font-family: 'kitab';
  font-size: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
}
#root {
  align-items: stretch;
  background-color: #f8f4ee;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  padding: 0;
}
.textEnglish {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Open Sans',
    sans-serif;
  font-size: 0.85em;
}
p {
  vertical-align: baseline;
}
.style {
  color: #f8f7f5;
}
